<template>
  <div style="position: absolute;right:20px;bottom:12px;line-height:20px;z-index: 999;display: flex;align-items: center;">
    <!-- <a-tooltip title="点击可换头像">
      <a-avatar @click="changeAvatar" shape="circle" :src="userInfo.avatar" style="margin-right:2px;cursor:pointer;" />
    </a-tooltip> -->
    <!-- <a-avatar shape="circle" :src="userInfo.imageUrl?userInfo.imageUrl:require('@/assets/default_img.png')"
      style="margin-right:10px;" :size="36"/> -->
      <!-- <span
        style="color:black;position: relative;cursor:pointer;margin-right: 20px;font-size: 14px;font-weight: bold;">{{userInfo.fullName}}
        <CaretDownOutlined />
      </span> -->
    <a-dropdown>
      <span
        style="color:black;position: relative;cursor:pointer;margin-right: 20px;font-size: 14px;font-weight: bold;">{{userInfo.fullName}}
        <CaretDownOutlined />
      </span>
      <template #overlay>
        <a-menu @click="onClickPeopleMenu" style="margin-top: 20px;">
          <!-- <a-menu-item key="pwd">
            <UnlockOutlined />修改密码
          </a-menu-item>
          <a-menu-item key="people">
            <TeamOutlined />个人资料
          </a-menu-item> -->
          <a-menu-item key="exit">
            <LoginOutlined />退出系统
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
  <!-- 更换头像 -->
  <!-- <a-modal v-model:visible="avatarModalVisible" title="更换头像" :bodyStyle="{display:'flex',justifyContent: 'center'}"
    @ok="submitAvatar">
    <a-upload :show-upload-list="false" :beforeUpload="pickAvatar">

      <div v-if="tempAvatar"
        style="cursor:pointer;border:1px dashed #CCCCCC;width:200px;height:200px;display: flex;flex-direction: column;justify-content: center;align-items: center;">
        <img :src="tempAvatar" style="width: 100%;height: 100%;" />
      </div>
      <div v-else
        style="cursor:pointer;border:1px dashed #CCCCCC;width:200px;height:200px;display: flex;flex-direction: column;justify-content: center;align-items: center;">
        <PlusOutlined :style="{fontSize:'35px',color:'#CCCCCC'}" />
      </div>
    </a-upload>
  </a-modal> -->
  <!-- 用户个人资料修改 -->
  <!-- <People ref="people" /> -->
  <!-- 用户个人密码修改 -->
  <!-- <Pwd ref="pwd" /> -->
</template>
<script>
  import ddo from '@/common/ddo.js'//数据字典对象
  import { removeLocalToken } from "@/common/token.js"
  import { PlusOutlined, UnlockOutlined, TeamOutlined, LoginOutlined, DownOutlined, CaretDownOutlined } from '@ant-design/icons-vue';
  import { defineComponent, ref, onMounted } from 'vue';
  import { useRouter } from "vue-router"
  import { message } from "ant-design-vue"
  import People from "../components/People.vue"
  import Pwd from "../components/Pwd.vue"
  import { myPost } from "@/common/request.js"
  const api = {
    execExitUrl: '/api/admin/system/user/logout.htm'
  };
  export default defineComponent({
    components: { PlusOutlined, message, People, Pwd, UnlockOutlined, TeamOutlined, LoginOutlined, DownOutlined, CaretDownOutlined },
    setup() {

      const router = useRouter()
      // const avatar = ref('')
      // const tempAvatar = ref('')
      // const avatarModalVisible = ref(false)
      const tempAvatarData = ref(null)
      // const changeAvatar = () => {
      //   avatarModalVisible.value = true
      // }
      const userInfo = ddo.state.userInfo
      //选择头像
      // const pickAvatar = (file, fileList) => {

      //   const fileReader = new FileReader();
      //   fileReader.addEventListener('load', () => {
      //     tempAvatar.value = fileReader.result;
      //     tempAvatarData.value = file;
      //   });
      //   fileReader.readAsDataURL(file)

      //   return false;

      // }
      // const submitAvatar = () => {
      //   const formData = new FormData();
      //   formData.append('avatar', tempAvatarData.value);
      //   avatarModalVisible.value = false;
      // }
      // 退出
      const execExit = () => {
        // myPost(api.execExitUrl, {}, {
        //   title: '提示',
        //   message: '您确定退出系统吗？'
        // }).then(() => {
          message.success('您已退出系统！');
          removeLocalToken();
        //   ddo.removeUserInfo()
          router.replace({
            path: '/login'
          });
        // })

      }

      const people = ref('')
      const pwd = ref('')
      const onClickPeopleMenu = ({ key }) => {
        switch (key) {

          case 'pwd':
            if (pwd && pwd.value) {

              pwd.value.go();
            }
            break;
          case 'people':
            if (people && people.value) {
              people.value.go({});
              // 从ddo获取最新数据进行回显
              people.value.refreshFormData();
            }
            break;
          case 'exit':
            execExit()
            break;
        }
      }
      return {
        userInfo,
        people,
        pwd,
        // avatar,
        // avatarModalVisible,
        // tempAvatar,
        // tempAvatarData,
        // changeAvatar,
        // pickAvatar,
        // submitAvatar,
        execExit,
        onClickPeopleMenu,
      }
    },
  });

</script>
<style>
  .xxx {}

  .home-container,
  #home {
    height: 100%;
    min-width: 1440px;
  }


  #home .trigger {
    font-size: 18px;
    line-height: 50px;
    padding: 0 10px;
    cursor: pointer;
    transition: color 0.3s;
  }

  #home .trigger:hover {
    color: #1890ff;
  }

  #home .logo {
    height: 50px;
    /* background: rgba(255, 255, 255, 0.2); */

    background-size: 85%;
    background-repeat: no-repeat;
    background-position: center;

  }
</style>