<template>
  <a-layout class="content">
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
      <div
        class="logo"
        style="margin-bottom: 10px;height: 60px;display: flex;align-items: center;justify-content: center; background: #002140;"
      >
        <img src="../assets/logo_layout.png" />
      </div>
      <a-menu mode="inline" theme="dark" :inline-collapsed="collapsed">
        <a-menu-item key="1">
          <router-link to="/dashboard">
            <span>首页</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="2">
          <router-link to="/curriculum">
            <span>课程管理</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="3">
          <router-link to="/consumer">
            <span>用户管理</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="4">
          <router-link to="/advertisement">
            <span>广告管理</span>
          </router-link>
        </a-menu-item>
        <a-sub-menu key="sub1">
          <template #title>
            <span>
              <span>系统管理</span>
            </span>
          </template>
          <a-menu-item key="5">
            <router-link to="/classification">
              <span>课程分类</span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="6">
            <router-link to="/administrators">
              <span>管理人员</span>
            </router-link>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background-color: #fff; padding: 0;position: relative;height: 60px;">
        <div
          style="text-align: center;line-height: 60px;font-size: 16px;font-weight: 500;color: #333333;position: relative;z-index: 5;box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);"
        >九喵学社</div>
        <Info />
      </a-layout-header>
      <a-layout-content :style="{ display: 'flex', flexDirection: 'column', paddingTop: '0' }">
        <div style="position: relative;">
          <a-breadcrumb
            style="line-height: 50px;box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);background-color: white;z-index: 0;position: relative;padding-left: 30px;"
          >
            <a-breadcrumb-item v-if="$route.meta.parentTitle">{{ $route.meta.parentTitle }}</a-breadcrumb-item>
            <a-breadcrumb-item>{{ $route.meta.title }}</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div
          style=" overflow: auto;background-color: #fff;margin: 20px 20px 0;padding: 30px 30px 0;min-height: 762px;"
        >
          <!-- <transition>
            <router-view class="enter"/>
          </transition>-->
          <router-view v-slot="{ Component }">
            <transition>
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </a-layout-content>
      <!-- <a-layout-footer style="text-align: center">版权所有&copy;西安索尔软件科技有限公司</a-layout-footer> -->
    </a-layout>
  </a-layout>
</template>
<script>
// import {
//   UserOutlined,
//   VideoCameraOutlined,
//   UploadOutlined,
//   MenuUnfoldOutlined,
//   MenuFoldOutlined,
//   SyncOutlined
// } from '@ant-design/icons-vue';
import { useRouter, useRoute } from "vue-router"
import { defineComponent, ref, reactive, toRefs, onMounted } from 'vue';
import Info from './components/userInfo';
// import Screenfull from '@/components/screenfull.vue';
import ddo from '@/common/ddo.js'//数据字典对象
// import { watch } from 'vue'
export default defineComponent({
  components: {
    // UserOutlined,
    // VideoCameraOutlined,
    // UploadOutlined,
    // MenuUnfoldOutlined,
    // MenuFoldOutlined,
    Info,
    // Screenfull,
    // SyncOutlined
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      selectedKeys: [],
      openKeys: [],
    });
    let menus = ref([])
    const handleClick = (item) => {
      if (item.key) {
        router.replace(item.key);
      } else {
        return
      }
    }
    //根据路由和菜单重新获取selectedKeys和openKeys的值
    // const queryDefaultOpen = (menu) => {

    //   console.log(route.path, menu, "===============");
    //   menu.forEach(item => {
    //     let flag = item.children.find(el => el.t.url == route.path)
    //     if (flag) {
    //       state.selectedKeys = [flag.id]
    //       state.openKeys = [item.id]
    //     } else {
    //       return
    //     }
    //   });
    // }
    // const queryDefaultOpen = (menu) => {

    //   let temRedirect = {
    //     selectedKeys: [],
    //     openKeys: [],
    //     redirectUrl: route.path
    //   }
    //   menu.forEach(item => {
    //     if (item.isChildren) {
    //       let flag = item.children.find(el => el.t.url == route.path)
    //       if (flag) {
    //         temRedirect.selectedKeys = [flag.t.id]
    //         temRedirect.openKeys = [item.t.id]
    //       } else {
    //         return
    //       }
    //     } else {

    //       if (item.t.url == route.path) {
    //         temRedirect.selectedKeys = [item.t.id]
    //       } else {
    //         return
    //       }
    //     }

    //   });

    //   ddo.setRedirect(temRedirect)
    //   state.selectedKeys = ddo.getRedirect().selectedKeys
    //   state.openKeys = ddo.getRedirect().openKeys
    // }
    // watch(ddo.state.menus, () => {
    //   menus.value = ddo.getMenu()
    //   state.selectedKeys = ddo.getRedirect().selectedKeys
    //   state.openKeys = ddo.getRedirect().openKeys

    // })
    onMounted(() => {
      // queryDefaultOpen(menus.value)

      // menus.value = ddo.getMenu()

      // // state.selectedKeys = ddo.getRedirect().selectedKeys
      // // state.openKeys = ddo.getRedirect().openKeys
      // if (route.path == '/') {
      //   // console.log(ddo.getRedirect().redirectUrl, "???????????????????????????????????????");

      //   // router.replace(ddo.getRedirect().redirectUrl);
      // } else {
      //   queryDefaultOpen(menus.value)

      // }
      console.log(route)

      if (route.path == '/') {
      //   // console.log(ddo.getRedirect().redirectUrl, "???????????????????????????????????????");

        router.replace('/dashboard');
      }
    })
    return {
      handleClick,
      collapsed: ref(false),
      menus,
      ...toRefs(state),
      ddo
      // queryDefaultOpen
    };
  },
});
</script>
<style>
.content {
  height: 100%;
  min-width: 1440px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
/* 
  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }

  .site-layout .site-layout-background {
    background: #fff;
  }

  .ant-breadcrumb>span:last-child {
    position: relative;
  }

  .ant-breadcrumb>span:last-child::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #637CFE;
    opacity: 1;
    bottom: -6px;
  }

  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #637CFE;
  } */
</style>