<template>
  <a-modal title="修改密码" v-model:visible="visible" @cancel="onCancel" @ok="execPwd"
    :after-close="()=>{$refs.form.resetFields()}" :maskClosable="false">

    <a-form :model="formState" :label-col="{span:5}" :wrapper-col="{span:16}" ref="form" :rules="rules">
      <a-form-item label="原密码" name="old_pwd" has-feedback>
        <a-input v-model:value="formState.old_pwd" type="password"></a-input>
      </a-form-item>
      <a-form-item label="新密码" name="new_pwd" has-feedback>
        <a-input v-model:value="formState.new_pwd" type="password"></a-input>
      </a-form-item>
      <a-form-item label="确认密码" name="re_pwd" has-feedback>
        <a-input v-model:value="formState.re_pwd" type="password"></a-input>
      </a-form-item>

    </a-form>

  </a-modal>
</template>

<script>
  const api = {
    execPwdUrl: '/api/admin/system/user/logoUpdate.htm'
  };
  import ddo from '@/common/ddo.js'//类vuex
  import { removeLocalToken } from "@/common/token.js"
  import { ref, reactive } from "vue"
  import { message } from 'ant-design-vue'
  import { encode64 } from "@/common/security.js";
  import { myPost } from "@/common/request.js"
  import { useRouter } from 'vue-router'
  export default {
    setup() {
      const router = useRouter()
      const visible = ref(false)//弹窗控制

      const onCancel = () => {
        visible.value = false;
      }
      // 打开弹窗
      const go = () => {
        visible.value = true;
      }
      const formState = reactive({
        old_pwd: '',
        new_pwd: '',
        re_pwd: ''
      })
      const form = ref('')//表单实例
      // 密码修改提交
      const execPwd = () => {
        form.value.validate()
          .then(() => {
            let params = {
              oldpassword: encode64(formState.old_pwd),
              password: encode64(formState.new_pwd),
              confirmpassword: encode64(formState.re_pwd),
            };
            myPost(api.execPwdUrl, params).then(() => {
              if (form.value) form.value.resetFields();
              visible.value = false;
              //  删除token和用户信息
              removeLocalToken();
              ddo.removeUserInfo()
              router.replace({ name: 'Login' });

              message.success("密码修改成功!")
            });
          })
          .catch(err => {
            console.log('error', err);
            message.error("密码修改失败,请检查输入是否有误!")
          });

      }
      // 确认密码验证
      let validator_re_pwd = async (rule, value, callback) => {
        if (value !== formState.new_pwd) {
          return Promise.reject("两次输入不一致!");
        } else {
          return Promise.resolve();
        }
      };
      const rules = {
        old_pwd: [
          {
            required: true,
            message: '请输入原密码',
            trigger: 'blur',
          }
        ],
        new_pwd: [
          {
            required: true,
            message: '请输入新密码',
            trigger: 'blur',
          },
        ],
        re_pwd: [
          {
            required: true,
            message: '请输入确认密码',
            trigger: 'blur',
          },
          {
            validator: validator_re_pwd,
          }
        ],

      };
      return {
        visible,
        formState,
        go,
        onCancel,
        execPwd,
        rules,
        form,
        ddo
      }
    }



  }
</script>

<style scoped>

</style>