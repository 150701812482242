<template>
  <a-modal title="个人资料" v-model:visible="visible" @cancel="visible = false;" @ok="execEdit"
    :after-close="()=>{$refs.form.resetFields();}" :maskClosable="false">
    <a-form ref="form" :model="formState" :label-col="{span:5}" :wrapper-col="{span:16}">

      <a-form-item label="头像" name="t_name">
        <a-tooltip title="点击可换头像">
          <a-avatar @click="$refs.input.click()" shape="circle" style="margin-right:2px;cursor:pointer;"
            :src="tempAvatar?tempAvatar:formState.imageUrl" />
        </a-tooltip>
        <input type="file" style="display: none;" ref="input" @change="selectAvatar">

      </a-form-item>
      <a-form-item label="工号" name="only">
        <a-input v-model:value="formState.only" disabled />
      </a-form-item>
      <a-form-item label="姓名" name="fullName">
        <a-input v-model:value="formState.fullName" :maxlength="10" />
      </a-form-item>
    </a-form>

  </a-modal>
</template>

<script>
  import { myPostFormData, myPost } from "@/common/request.js"

  const api = {
    editUrl: '/api/admin/system/user/updateAdminUser.htm',//个人中心数据修改
    uploadUrl: '/api/common/upload/upload.htm'//上传头像
  };
  import ddo from '@/common/ddo.js';
  import { ref, reactive, onMounted, watch, toRefs } from 'vue'
  import { message } from 'ant-design-vue'

  export default {
    setup() {
      const form = ref('')//表单实例
      const visible = ref(false)//弹窗控制
      const tempAvatar = ref('')//在上传的时候本地显示图片
      const formState = reactive({
        fullName: '',
        only: '',
        id: '',
        imageUrl: ''

      })
      // 打开弹窗方法
      const go = () => {
        visible.value = true
      }
      // 修个个人资料提交
      const execEdit = () => {
        let params = {
          ...formState
        }
        // 如果tempAvatar不存在表示没有上传头像
        if (!tempAvatar.value && params.imageUrl) {
          params.imageUrl = params.imageUrl.split('shengwutezheng')[1]
        }
        myPost(api.editUrl, params).then((res) => {
          // 本地所在的修改同步到用户信息
          Object.assign(ddo.state.userInfo, res.data)
          message.success("个人资料已经成功修改！")
          visible.value = false
          tempAvatar.value = ''
        })

      }
      // 选择图片上传并存返回url浏览地址
      const selectAvatar = (e) => {
        let formdata = new FormData()
        formdata.append("mfile", e.target.files[0])
        formdata.append("type", 1)
        if (e.target.files[0].size > 1024 * 1024) {
          message.warning('头像大小不可超过1MB')
          return
        }
        myPostFormData(api.uploadUrl, formdata).then((res) => {
          formState.imageUrl = res.data[0].path
          tempAvatar.value = res.data[0].fullPath
        })
      }
      // 初始化数据回显
      const refreshFormData = () => {
        formState.fullName = ddo.state.userInfo.fullName
        formState.only = ddo.state.userInfo.only
        formState.imageUrl = ddo.state.userInfo.imageUrl ? ddo.state.userInfo.imageUrl : ''
        formState.id = ddo.state.userInfo.id
      }
      return {
        visible,
        formState,
        tempAvatar,
        form,
        go,
        execEdit,
        selectAvatar,
        ddo,
        refreshFormData
      }
    }


  }
</script>

<style>
</style>